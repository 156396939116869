import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/codebuild/output/src170988513/src/solar-energy-partners/src/templates/page.js";
import DealerForm from '../components/DealerForm';
import SmoothScrollButton from '../components/SmoothScrollButton';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Hero = makeShortcode("Hero");
const HeroImage = makeShortcode("HeroImage");
const HeroContent = makeShortcode("HeroContent");
const Container = makeShortcode("Container");
const SplitSection = makeShortcode("SplitSection");
const Section = makeShortcode("Section");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Hero mdxType="Hero">
      <HeroImage mdxType="HeroImage">
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1920px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "66.66666666666666%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGQAAAgMBAAAAAAAAAAAAAAAAAAUBAgME/8QAFQEBAQAAAAAAAAAAAAAAAAAAAAL/2gAMAwEAAhADEAAAAYrxZ1DIVB//xAAZEAADAQEBAAAAAAAAAAAAAAAAARICBBD/2gAIAQEAAQUCakotD6NMp68//8QAFhEBAQEAAAAAAAAAAAAAAAAAABIB/9oACAEDAQE/AZxL/8QAFhEBAQEAAAAAAAAAAAAAAAAAABIB/9oACAECAQE/Ab1b/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAGhABAAIDAQAAAAAAAAAAAAAAAQARECExUf/aAAgBAQABPyFRslAvF0SLrlvs/9oADAMBAAIAAwAAABCc3//EABYRAQEBAAAAAAAAAAAAAAAAAAEQQf/aAAgBAwEBPxBwI//EABYRAAMAAAAAAAAAAAAAAAAAAAEQUf/aAAgBAgEBPxCxX//EABsQAQACAwEBAAAAAAAAAAAAAAEAESExUUGB/9oACAEBAAE/EEyQdhYCs6SoJoH7FqJv25aCFHIKuW5//9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/8147997f6d6c39de5bca9aaca1d330fb/d3be9/energy-independence.webp 480w", "/static/8147997f6d6c39de5bca9aaca1d330fb/e46b2/energy-independence.webp 960w", "/static/8147997f6d6c39de5bca9aaca1d330fb/882b9/energy-independence.webp 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/8147997f6d6c39de5bca9aaca1d330fb/7cc5e/energy-independence.jpg 480w", "/static/8147997f6d6c39de5bca9aaca1d330fb/6a068/energy-independence.jpg 960w", "/static/8147997f6d6c39de5bca9aaca1d330fb/0f98f/energy-independence.jpg 1920w"],
                "sizes": "(max-width: 1920px) 100vw, 1920px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/8147997f6d6c39de5bca9aaca1d330fb/0f98f/energy-independence.jpg",
                "alt": "Solar Energy Careers",
                "title": "Solar Energy Careers",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </HeroImage><HeroContent mdxType="HeroContent">
        <Container mdxType="Container">
          <h1>{`Dealer Program`}</h1>
          <SmoothScrollButton style={{
            fontSize: 14
          }} target="contact" mdxType="SmoothScrollButton">
    Click Here to Learn More
          </SmoothScrollButton>
        </Container>
      </HeroContent></Hero>
    <SplitSection img='solar-delivery' title='' theme='dark' background='#ffffff' mdxType="SplitSection">
      <p>{`Have you been interested in building your own solar team, but you feel overwhelmed and not sure where to start? Let SEP take the burden off your shoulders and help you launch an easily scalable solar business. With no franchise fees, training from business owners who have done it before, and 24/7 access to SEP support, we will be with you every step of the way to success.`}</p>
    </SplitSection>
    <SplitSection img='power-savings' title='' position='left' mdxType="SplitSection">
      <p>{`Our tools and support will help you bring your customers to install faster than anyone in the industry. This is unlike most franchise opportunities, SEP is here to handle it all. We have local installers with years of experience ready to serve your solar installation needs.`}</p>
    </SplitSection>
    <SplitSection img='environment' title='' theme='dark' background='#ffffff' mdxType="SplitSection">
      <p>{`You will always have a leg up on the competition with our technology that scales with you and provides real-time dynamic proposals and contract packages that include installation and financing! This will help take the pain out of hours of paperwork off your plate. Talk to one of our reps today about starting your solar career!`}</p>
      <SmoothScrollButton style={{
        fontSize: 14
      }} target="contact" mdxType="SmoothScrollButton">
    Click Here to Learn More
      </SmoothScrollButton>
    </SplitSection>
    <Section mdxType="Section">
      <Container style={{
        textAlign: 'center'
      }} mdxType="Container">
        <h2>{`Our Service Areas`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "1280px"
            }
          }}>{`
      `}<span parentName="span" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "61.45833333333333%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAMABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIEAQb/xAAVAQEBAAAAAAAAAAAAAAAAAAABAv/aAAwDAQACEAMQAAAByiBLGOiJf//EABsQAQACAgMAAAAAAAAAAAAAAAEAAgMREhMh/9oACAEBAAEFAsVgLcWLt35MdTr/AP/EABQRAQAAAAAAAAAAAAAAAAAAABD/2gAIAQMBAT8BP//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAECAQE/AVf/xAAZEAACAwEAAAAAAAAAAAAAAAAAIQIQQRL/2gAIAQEABj8CY65yorD/xAAbEAACAgMBAAAAAAAAAAAAAAAAASExEBFBYf/aAAgBAQABPyFyUJsRdDcsT+ycURTh/9oADAMBAAIAAwAAABCwD//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAEDAQE/EEn/xAAWEQEBAQAAAAAAAAAAAAAAAAABEGH/2gAIAQIBAT8QHI//xAAbEAEAAwADAQAAAAAAAAAAAAABABEhMUFRcf/aAAgBAQABPxBTKtrGfLhnj60t+QsgFw4o6nPCy2bcVq/DIx1VbR4T/9k=')",
                "backgroundSize": "cover",
                "display": "block"
              }
            }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/13304396e994caec3742aa170802e788/d3be9/service-areas.webp 480w", "/static/13304396e994caec3742aa170802e788/e46b2/service-areas.webp 960w", "/static/13304396e994caec3742aa170802e788/af3f0/service-areas.webp 1280w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/webp"
              }}></source>{`
          `}<source parentName="picture" {...{
                "srcSet": ["/static/13304396e994caec3742aa170802e788/7cc5e/service-areas.jpg 480w", "/static/13304396e994caec3742aa170802e788/6a068/service-areas.jpg 960w", "/static/13304396e994caec3742aa170802e788/eea4a/service-areas.jpg 1280w"],
                "sizes": "(max-width: 1280px) 100vw, 1280px",
                "type": "image/jpeg"
              }}></source>{`
          `}<img parentName="picture" {...{
                "className": "gatsby-resp-image-image",
                "src": "/static/13304396e994caec3742aa170802e788/eea4a/service-areas.jpg",
                "alt": "Solar Energy Partners Service Areas",
                "title": "Solar Energy Partners Service Areas",
                "loading": "lazy",
                "decoding": "async",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                }
              }}></img>{`
        `}</picture>{`
    `}</span></p>
      </Container>
    </Section>
    <Section id="contact" mdxType="Section">
      <Container style={{
        maxWidth: 800
      }} mdxType="Container">
        <h2 style={{
          textAlign: 'center'
        }}>Contact Us To Learn More Today!</h2>
        <DealerForm mdxType="DealerForm" />
      </Container>
    </Section>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      